import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import * as firebase from 'firebase/messaging';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class FirebaseMessagingService {
  message: any = null;
  deviceToken: string | null = null;
  vapidKey =
    'BGPB4oqkAsWBo1B5j7LWmsi8nWKJy4qVzOmecEdilNw7gkAj1VnfVv2JTcLw1-BEMOs3YB_DkdrmZcRSq7XZ9Nk';
    apiKey = environment.API_URL;
  constructor(
    private afMessaging: AngularFireMessaging,
    private _appStoreFacade: AppStoreFacade,
    private _http: HttpClient,
  ) {
    initializeApp(environment.firebase);
  }
  async requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: this.vapidKey })
      .then((currentToken) => {
        console.log('DeviceToken-------', currentToken);
          this.deviceToken = currentToken;
          localStorage.setItem("deviceToken",currentToken)
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }
  listenForMessages() {
    const messaging = getMessaging();
    onMessage(messaging, (payload: any) => {
      if (Notification.permission === 'granted') {
        const { title, body } = payload.notification;
        const notification = new Notification(title, {
          body,
          icon: './../../../assets/images/fashquik_logo.svg',
        });
        notification.onclick = () => {
          window.open('https://web.stg.fashquik.com/', '_blank');
        };
      }
    });
  }
  pushNotification(data: any) {
    return this._http.post(`${this.apiKey}/push/notification`, data);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from './guards/logged-in.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'product-variant',
    loadChildren: () => import('./pages/product-variant/product-variant.module').then(m => m.ProductVariantModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryModule)
  },
  {
    path: 'brands',
    loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule)
  },
  {
    path: 'partners',
    loadChildren: () => import('./pages/partners/partners.module').then(m => m.PartnersModule)
  },
  {
    path: 'setup-configuration',
    loadChildren: () => import('./pages/setup-configuration/setup-configuration.module').then(m => m.SetupConfigurationModule)
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'product-catalogue',
    loadChildren: () => import('./pages/product-catalogue/product-catalogue.module').then(m => m.ProductCatalogueModule)
  },
  {
    path: 'product-in-review',
    loadChildren: () => import('./pages/product-in-review/product-in-review.module').then(m => m.ProductInReviewModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service'; // Assuming your AuthService import
import { SnackbarService } from '../common/snackbar.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService, private _snackbarService :SnackbarService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.authService.getAuthToken();
    let authReq = request;
    
    if (authToken) {
      authReq = request.clone({
        setHeaders: { Authorization: `Bearer ${authToken}` }
      });
    }  
    return next.handle(authReq).pipe(
      catchError((err: HttpErrorResponse) => {
        if ((err.status === 401 && (err.error?.message?.includes('Unauthorized') || err.statusText === 'Unauthorized')) ||
        (err.status === 403 && err.error?.message?.includes('Invalid token or Permission denied.'))) {
          this.authService.clearAuthToken();
          this.router.navigate(['/auth/login']);
          return throwError(() => new Error('Unauthorized or Permission denied')); // Re-throw for clarity
        }
        // Handle other errors
        return throwError(() => err);
      })
    );
  }  
}
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  constructor(private _router: Router){}
  canActivate(): boolean {
    let response: any;
    if(localStorage?.getItem('access_token')){
      response = true;
    } else {
      response = false;
      this._router.navigateByUrl('/auth');
    }
    return response;
  }
  
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.API_URL;
  private readonly TOKEN_KEY = 'access_token';
  apiEndPoint = {
    login:"/user/login",
    changePassword:"/superAdmin/change-password"
  }
  constructor(private http: HttpClient) {}

  setAuthToken(token: string): void {
    localStorage.setItem(this.TOKEN_KEY, token);
  }
  changePasswordApi(payLoad: any, token: string) {
    return this.http.post<any>(`${this.apiUrl}${this.apiEndPoint.changePassword}`, payLoad);
  }
  getAuthToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  clearAuthToken(): void {
    localStorage.removeItem(this.TOKEN_KEY);
  }
  login(credentials: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/superAdmin/login`, credentials).pipe(
      map((result: any) => {
        if(result && result.token){
          this.setAuthToken(result.token);
        }
        return result;
      })
    );
  }

  getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    if (this.getAuthToken()) {
      headers = headers.set('Authorization', `Bearer ${this.getAuthToken()}`);
    }
    return headers;
  }

  createPasswordApi(payLoad: any, token: string) {
    return this.http.post<any>(`${this.apiUrl}/superAdmin/confirm-password?token=${token}`, payLoad);
  }

  sendPasswordResetInstructions(email: string) {
    return this.http.post<any>(`${this.apiUrl}/password-reset`, { email });
  }


  passwordValidator(): ValidatorFn {
    return (control): ValidationErrors | null => {
      const value = control.value;
      const errors: any = {};
      if (value.length < 8) {
        errors.minLength = true;
      }
      if (!/[A-Z]/.test(value)) {
        errors.uppercase = true;
      }
      if (!/[a-z]/.test(value)) {
        errors.lowercase = true;
      }
      if (!/\d/.test(value)) {
        errors.digit = true;
      }
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
        errors.specialCharacter = true;
      }
      return Object.keys(errors).length ? errors : null;
    };
  }

  passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
    const oldPassword = control?.get('oldPassword')?.value;
    const newPassword = control?.get('newPassword')?.value;
    const reenterPassword = control?.get('reenterPassword')?.value;
    if (newPassword  && newPassword === oldPassword) {
      control.get('newPassword')?.setErrors({ sameAsOld: true });
      return { sameAsOld: false };
    }
    if (newPassword !== reenterPassword) {
      control.get('reenterPassword')?.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    } else {
      control.get('reenterPassword')?.setErrors(null);
    }
    return null;
      };
  }
  
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AppStoreFacade } from './+state/app-store.facade';
import { DeviceDetectorResult } from 'device-detector-js';
import * as DeviceDetector from 'device-detector-js';
import { Subject, delay, takeUntil } from 'rxjs';
import { FirebaseMessagingService } from './services/pushNotification/firebase-messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  title = 'fak-corems';

  isLoading: boolean = false;

  device: DeviceDetectorResult | undefined;
  deviceDetector = new DeviceDetector();

  private _unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _appStoreFacade: AppStoreFacade,
    private _cdr: ChangeDetectorRef,
    private _ngZone: NgZone,
    private messagingService: FirebaseMessagingService
  ){
    try {
      this.device = this.deviceDetector.parse(window.navigator.userAgent);
    } catch (error) {
      
    }

    this._appStoreFacade.isLoading$.pipe(takeUntil(this._unsubscribe$), delay(0))
    .subscribe((visibility: any) => {
      this.isLoading = visibility;
      this._cdr.markForCheck();
    });

    this._appStoreFacade.updateDeviceDetails(this.device);
  }

  ngOnInit(): void {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((err) => {
      console.error('Service Worker registration failed:', err);
    });
       this.messagingService.requestPermission();
      this.messagingService.listenForMessages();
    
  }
  Notifications() {
    const body = {
      registrationTokens: [localStorage.getItem('deviceToken')],
      title: 'Hello !',
      body: 'You have new order !!!',
      sound: 'src/assets/Notification/livechat-129007.mp3'
    };
    this.messagingService.pushNotification(body).subscribe((gigi: any) => {
      return gigi;
    });
  }
}
